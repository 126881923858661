import { Card, Button, Image } from "react-bootstrap";
import Ratio from "react-bootstrap/Ratio";

const InfoCard = (props) => {
  const {
    title,
    body,
    image,
    imageAlt,
    buttonText,
    buttonPath,
    buttonText2,
    buttonPath2,
  } = props;
  return (
    <Card bg="dark" text="white">
      {image && imageAlt ? (
        <Ratio aspectRatio={2 / 3}>
          <Image className="card-image" src={`${image}`} alt={imageAlt}></Image>
        </Ratio>
      ) : null}
      <Card.Title className="card-title">{title}</Card.Title>
      <Card.Body className="card-body">{body}</Card.Body>
      <div className="card-buttons">
        {buttonText && buttonPath ? (
          <Button
            className="card-button"
            href={`${buttonPath}`}
            target="_blank"
            variant="light"
            size="sm"
          >
            {buttonText}
          </Button>
        ) : null}
        {buttonText2 && buttonPath2 ? (
          <Button
            className="card-button card-button-last"
            href={`${buttonPath2}`}
            target="_blank"
            variant="light"
            size="sm"
          >
            {buttonText2}
          </Button>
        ) : null}
      </div>
    </Card>
  );
};

export default InfoCard;
