import { Container, Row, Col, Image, CardGroup } from "react-bootstrap";
import InfoCard from "../components/InfoCard";
import SunRush from "../assets/images/sunrush.png";
import Gardenize from "../assets/images/gardenize.png";
import HiveHQ from "../assets/images/hive-hq.png";
import PlateMate from "../assets/images/platemate.png";
import FootPatrol from "../assets/images/foot-patrol-2.0.png";
import CodecademyDiscordBot from "../assets/images/codecademy-discord-bot.png";
import Headshot from "../assets/images/headshot.jpg";

const Main = () => {
  return (
    <div id="background">
      <Container id="main">
        <Row id="row-1">
          <Col id="col-1">
            <Image
              id="portrait"
              src={Headshot}
              alt="Victoria Da Rosa"
              roundedCircle
            />
          </Col>
          <Col id="col-2">
            <p className="body-text">👋 Hi, I'm</p>
            <h1 className="title" id="name">
              Victoria.
            </h1>
            <div className="socials">
              <div className="link-wrapper-icon">
                <a
                  href="mailto:hello@victoriadarosa.com"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Email hello@victoriadarosa.com"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-envelope-fill socials-icon"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                  </svg>
                </a>
              </div>
              <div className="link-wrapper-icon">
                <a
                  href="https://github.com/Victoria-DR"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit Victoria's GitHub profile"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-github socials-icon"
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                  >
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                  </svg>
                </a>
              </div>
              <div className="link-wrapper-icon">
                <a
                  href="https://linkedin.com/in/victoriadarosa"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Visit Victoria's LinkedIn profile"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-linkedin socials-icon"
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                  >
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                  </svg>
                </a>
              </div>
              <div>
                <a
                  className="site-link"
                  href="./VictoriaDaRosa_resume.pdf"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Download Victoria's resume"
                >
                  Resume
                </a>
              </div>
            </div>
            <p className="body-text">
              I'm a computer engineering student at the University of Waterloo
              who loves finding creative solutions to problems big and small. I
              will be working as a software developer at eSentire this fall. My
              previous internship experience include roles as a software
              engineer at Sun Life, a full-stack web developer at Genesys, a
              technical writer at IBM, and a web developer in test at
              RetinaLogik. I'm currently looking for summer 2025 internship and
              co-op opportunities, please feel free to get in touch!
            </p>
            <br />
            <p className="body-text">
              Check out some of my{" "}
              <span>
                <a className="site-link" href="#projects">
                  projects
                </a>
              </span>{" "}
              below!
            </p>
          </Col>
        </Row>
      </Container>
      <Container id="projects" className="pb-5">
        <Row>
          <Col>
            <h1 className="title">Projects</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <CardGroup className="card-row">
              <InfoCard
                title="SunRush"
                body="An educational augmented reality adventure game designed to combat summer learning loss in young children. I developed all server-side functionality and API routes with Next, client-side components with React and Tailwind CSS, AR features with A-Frame and AR.js, and location-based services with the Google Places API. I also enabled storage capabilities with MongoDB Atlas and AWS S3, implemented authentication with Auth0, and configured CI/CD pipelines with GitHub Actions, Vercel, and GoDaddy. 1st place overall at ElleHacks 2024."
                image={SunRush}
                imageAlt="SunRush logo"
                buttonText="GitHub"
                buttonPath="https://github.com/Victoria-DR/ellehacks-2024"
                buttonText2="Devpost"
                buttonPath2="https://devpost.com/software/sunrush"
              />
              <InfoCard
                title="Gardenize"
                body="A community gardening app that features personalized plant recommendations and trackers, a social platform for users to share ideas and updates, and a gardening chatbot. I built the Express back end, Firebase Cloud Firestore and Cloud Storage databases, and Autocode endpoints with OpenAI's Davinci model and Stability AI. I also connected Cohere's NLP models and the React front end to the back end. 2nd place overall and 1st place in the Best Use of Cohere API category at ElleHacks 2023."
                image={Gardenize}
                imageAlt="Gardenize logo"
                buttonText="GitHub"
                buttonPath="https://github.com/Victoria-DR/ellehacks-2023"
                buttonText2="Devpost"
                buttonPath2="https://devpost.com/software/gardenizer"
              />
              <InfoCard
                title="Hive-HQ"
                body="An enterprise management tool that monitors and improves the safety of a store or public area against COVID-19, tracking foot traffic and displaying a live heat map overlaid on a floor plan. I created a high-fidelity mockup of the site with Figma and implemented its design and logic with React and JavaScript. Winner of Best Entrepreneurship Hack and Best Use of Google Cloud at Hack the North 2021."
                image={HiveHQ}
                imageAlt="Hive-HQ logo"
                buttonText="GitHub"
                buttonPath="https://github.com/Hive-HQ"
                buttonText2="Devpost"
                buttonPath2="https://devpost.com/software/hive-hq"
              />
            </CardGroup>
            <CardGroup className="card-row">
              <InfoCard
                title="PlateMate"
                body="A networking platform that matches nearby individuals to get good food together at suggested restaurants. I built the Express back end and the Firebase Cloud Firestore database. I also integrated the Google Places API and implemented and engineered prompts for Cohere. 3rd place winner of the Best Build with Cohere Challenge at UofTHacks X."
                image={PlateMate}
                imageAlt="PlateMate logo"
                buttonText="GitHub"
                buttonPath="https://github.com/mohsen-ameli/uofthacks-x"
                buttonText2="Devpost"
                buttonPath2="https://devpost.com/software/platemate"
              />
              <InfoCard
                title="Foot Patrol 2.0"
                body="A mobile app that connects university foot patrol volunteers with students needing a safe walk home at night. I designed a clean and user-friendly UI using Figma and implemented the front end using React Native. Winner of the General Motors Canada: Speak Up for Safety! challenge and Best Use of Google Cloud at ElleHacks 2022."
                image={FootPatrol}
                imageAlt="Foot Patrol 2.0 logo"
                buttonText="GitHub"
                buttonPath="https://github.com/michpara/Foot-Patrol"
                buttonText2="Devpost"
                buttonPath2="https://devpost.com/software/foot-patrol"
              />
              <InfoCard
                title="Codecademy Community"
                body="As a volunteer moderator in the Codecademy Community, I empower individuals learning to code and helped to grow the community from under 4,000 members to over 112,000 members today. I was a maintainer and contributor for three open-source community projects: a custom moderation bot (built with Node.js, MySQL, and discord.js), a community website (built with React and Bulma), and a community-use bot (built with Node.js and discord.js)."
                image={CodecademyDiscordBot}
                imageAlt="Codecademy Community moderation bot commands"
                buttonText="GitHub"
                buttonPath="https://github.com/CodecademyCommunity/codecademy-discord-bot"
              />
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Main;
