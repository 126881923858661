import { Container, Row, Col } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <Container>
      <Row>
        <Col>
          <div id="not-found-wrapper">
            <h1 className="title" id="not-found-title">
              404
            </h1>
            <p className="body-text" id="not-found-text">
              Page Not Found
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
